import React from 'react'
import { Text, TextProps, Sx, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

interface TextHeadingProps {
  sx?: Sx
  weight?: TextProps<'div'>['weight']
  align?: TextProps<'div'>['align']
  children: React.ReactNode
}

const TextHeading: React.FC<TextHeadingProps> = ({ sx, weight = 600, align, children }) => {
  const theme = useMantineTheme()

  const isMediumScreen = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`, true)

  const styles: Sx = (t) => {
    const extraStyles = typeof sx === 'function' ? sx(t) : sx || {}

    const defaultFontSize = isMediumScreen ? t.other.textHeading.fontSizeDesktop : t.other.textHeading.fontSizeMobile

    // User-provided styles are prioritized, otherwise defaults are used
    const fontSize = extraStyles.fontSize ?? defaultFontSize

    // Web does not support percentage-based letter-spacing so we have to do this work-around
    const defaultLetterSpacing = isNaN(fontSize) ? 'normal' : -(fontSize * 0.03)

    // User-provided letterSpacing are prioritized, otherwise we use `-3%`.
    const letterSpacing = extraStyles.letterSpacing ?? defaultLetterSpacing

    return {
      color: t.colors.gray[9],
      fontFamily: t.other.headingFontFamily,
      ...extraStyles,
      fontSize,
      letterSpacing
    }
  }

  return (
    <Text inline weight={weight} align={align} sx={styles}>
      {children}
    </Text>
  )
}

export { TextHeading }

import React, { useEffect, useState } from 'react'
import { Box, Sx } from '@mantine/core'

interface TextHeadingAlignmentFixProps {
  sx?: Sx
  adjustment?: 'sm' | 'md'
}

const adjustments = {
  sm: 2,
  md: 4
}

// We probably should move it elsewhere, but `TextHeading` (which uses the PPTelegraf font family)
// is misaligned on Windows due to how it renders the font: it takes into account for letters that go
// below the baseline like j, g, p, etc. but not for upper letters like T, H, etc. This is a drop-in
// temporary workaround for cases like "253 STRD" where none of the letters are below baseline. Some
// with lower baselines need some alignment help too so we added `adjustment` prop just in case.
//
// We'll use useState and useEffect to make sure this only renders on the client side to avoid SSR issues.
const TextHeadingAlignmentFix = (props: React.PropsWithChildren<TextHeadingAlignmentFixProps>) => {
  const [isWindows, setIsWindows] = useState(false)

  useEffect(() => {
    if (navigator.userAgent.includes('Win')) {
      setIsWindows(true)
    }
  }, [isWindows])

  const adjustment = props.adjustment ?? 'md'

  const styles: Sx = (t) => {
    const extraStyles = typeof props.sx === 'function' ? props.sx(t) : props.sx || {}

    return {
      ...extraStyles,
      transform: isWindows ? `translateY(${adjustments[adjustment]}px)` : 'translateY(0px)'
    }
  }

  return <Box sx={styles}>{props.children}</Box>
}

export { TextHeadingAlignmentFix }

import React from 'react'
import { Badge, Box, Container, Group, Stack } from '@mantine/core'

import { env } from '@/utils'
import { CHAIN_SUPPORTS_REFERRAL } from '@/config'
import { useSelectedCoin } from '@/contexts/wallet'
import { GlobalAnnouncement } from '../GlobalAnnouncement'
import { useReferral } from '../Referral'
import { TopBanner } from './TopBanner'
import { NavigationMobileMenu, NavigationMobileMenuProvider } from './NavigationMobileMenu'
import { NavigationWalletDropdown } from './NavigationWalletDropdown'
import { SidebarLite } from './SidebarLite'

interface GlobalLayoutProps {
  children: React.ReactNode
}

const GlobalLayout: React.FC<GlobalLayoutProps> = ({ children }) => {
  const { referral } = useReferral()

  const selectedCoinDenom = useSelectedCoin()

  const isProduction = !['development', 'staging'].includes(env('STRIDE_ENV'))

  return (
    <>
      <Group spacing={0} align="flex-start">
        <SidebarLite />

        <Stack sx={{ flex: 1 }}>
          <GlobalAnnouncement />

          <Box sx={{ width: '100%' }}>
            <TopBanner />

            <Container px={16} fluid>
              <Group align="center" position={isProduction ? 'right' : 'apart'} py={16} spacing={0}>
                {!isProduction && (
                  <Group spacing={10}>
                    <Badge color={env('STRIDE_ENV') === 'development' ? 'gray' : 'yellow'} variant="filled" size="xs">
                      {env('STRIDE_ENV')}
                    </Badge>

                    {referral && CHAIN_SUPPORTS_REFERRAL[selectedCoinDenom] && (
                      <Badge color="pink" variant="filled" size="xs">
                        Referral code: {referral}
                      </Badge>
                    )}
                  </Group>
                )}

                <Group spacing="sm">
                  <NavigationWalletDropdown />

                  <NavigationMobileMenuProvider>
                    <NavigationMobileMenu />
                  </NavigationMobileMenuProvider>
                </Group>
              </Group>
            </Container>

            <Box pb="xl">{children}</Box>
          </Box>
        </Stack>
      </Group>
    </>
  )
}

export { GlobalLayout }
